export default function () {
  function fnResize() {
    var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
    if (deviceWidth >= 750) {
      deviceWidth = 750;
    }
    if (deviceWidth <= 320) {
      deviceWidth = 320;
    }
    document.documentElement.style.fontSize = deviceWidth / 7.5 + 'px';
  }
  var devicePixelRatio = 1;
  var scale = 1 / devicePixelRatio;
  document.querySelector('meta[name="viewport"]').setAttribute('content', 'initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale + ', user-scalable=no');
  fnResize();
  window.onresize = function () {
    fnResize();
  };
}
