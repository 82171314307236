// 生成随机字符
export function randomString(len) {
  len = len || 32;
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678';
  var maxPos = $chars.length;
  var pwd = '';
  for (var i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

export const getUrlparam = (param) => {
  const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg) || window.location.hash.substring(window.location.hash.search(/\?/) + 1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
};

export function getHashParameters() {
  var arr = (location.hash || '').split('?')[1]?.split('&') || [];
  var params = {};
  for (var i = 0; i < arr.length; i++) {
    var data = arr[i].split('=');
    if (data.length === 2) {
      params[data[0]] = data[1];
    }
  }
  return params;
}

// 获取设备name
export function getDeviceType() {
  var u = navigator.userAgent,
    app = navigator.appVersion;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || navigator.userAgent.indexOf('Macintosh') > -1; //ios终端
  if (isAndroid) {
    return 'android';
  }
  if (isiOS) {
    return 'ios';
  }
  return 'other';
}

export function myToFixed(num, n) {
  if (n > 20 || n < 0) {
    throw new RangeError('toFixed() digits argument must be between 0 and 20');
  }
  const number = num;
  if (isNaN(number) || number >= Math.pow(10, 21)) {
    return number.toString();
  }
  if (typeof n === 'undefined' || n == 0) {
    return Math.round(number).toString();
  }
  let result = number.toString();
  const arr = result.split('.'); // 整数的情况
  if (arr.length < 2) {
    result += '.';
    for (let i = 0; i < n; i += 1) {
      result += '0';
    }
    return result;
  }
  const integer = arr[0];
  const decimal = arr[1];
  if (decimal.length === n) {
    return result;
  }
  if (decimal.length < n) {
    for (let i = 0; i < n - decimal.length; i += 1) {
      result += '0';
    }
    return result;
  }
  result = `${integer}.${decimal.substr(0, n)}`;
  const last = decimal.substr(n, 1); // 四舍五入，转换为整数再处理，避免浮点数精度的损失
  if (parseInt(last, 10) >= 5) {
    const x = Math.pow(10, n);
    result = (Math.round(parseFloat(result) * x) + 1) / x;
    result = result.toFixed(n);
  }
  return result;
}
