import { getUrlparam } from '@/utils';
import { postMsgToIos } from '@/utils/bridge';

function setInfo(data) {
  data.appid ? window.sessionStorage.setItem('appid', data.appid) : '';
  data.appkey ? window.sessionStorage.setItem('appKey', data.appkey) : '';
  data.accountSt ? window.sessionStorage.setItem('accountSt', data.accountSt) : '';
  data.channel_id ? window.sessionStorage.setItem('channel_id', data.channel_id || 1001) : '';
  data.ato ? window.sessionStorage.setItem('ato', data.ato) : '';
  data.accountName ? window.sessionStorage.setItem('accountName', data.accountName) : '';
  data.pf ? window.sessionStorage.setItem('pf', data.pf) : '';
  data.openid ? window.sessionStorage.setItem('openid', data.openid) : '';
}
window.android ? window.android.callGetAppkey() : '';
window.getParams = function (data) {
  if (typeof data === 'string') {
    data = JSON.parse(data);
  }
  //字段转换,ios 和 安卓传递的参数是chanleId 需要转换成后端使用的channel_id
  if (data.chanleId) {
    data.channel_id = data.chanleId;
  }
  //这个方法是在安卓和ios触发的方法,只要触发了 pf值就是app否则就是h5
  data.pf = 'app';
  setInfo(data);
};

postMsgToIos('callGetAppkey');

const data = {
  appid: '10003',
  appkey: 'ffb47fa4d0dd55f49146c6d7f42b6125',
  ato: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyIjp7Im9wZW5pZCI6Ik1UQXdNRE10T0RBd01EQXhOVGtfIiwicm9sZV9pZCI6Ik1UQXdNRE10T0RBd01EQXhOVGtfIiwic2VydmVyX2lkIjoiMSJ9LCJhdWQiOiI2NDExM2NkNzU0MmNiIiwiaXNzIjoiNjQxMTNjZDc1NDJjZiIsImV4cCI6MTY4MTQ0MzI4NywianRpIjoiTVRBd01ETXRPREF3TURBeE5Ua18ifQ.Z8q7iYj985WxjwJVWO4CYhauvQlA5FnH5vMy1FnG-EDzXOgHbung6OpfmeZs54Bl2YRJaYE7X-EyzBzNKuVjhs6Tt7uFfLvH4QLGUs9n2lg8Lx02ibu7WU9fRuzvzkchNCNFtcCAdFjmDWCbhwao2x0w0mb5JzcsAeDvDb1DVrrXHz40ETNnqk__WFDuIDXnOir5qjyuwD-JQ4oDm2n0fNP9v548VRYIpSBPyxy6qI9Bkd59PPfEmLwYxpADDaIw1shnu-coubRLwFZb9orXUpvXMrIkOcXSROQ9SJH5tfY5gN_0Z25XJIGH6bf41sVm2fuvHhiP7AVibHaUnkdn3A',
  accountName: 'ceshihao',
  accountSt: '35',
  channel_id: '1001',
  pf: 'h5',
  openid: 'MTAwMDMtODAwMDAxNTk_',
};

if (data.appid && data.appkey && data.ato) {
  setInfo(data);
}
