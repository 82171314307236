import Vue from 'vue';
import VueRouter from 'vue-router';

const HomePage = () => import('@/views/HomePage/HomePage');
const Withdrawal = () => import('@/views/HomePage/ChildPage/Withdrawal');
const MoneyDetail = () => import('@/views/HomePage/ChildPage/MoneyDetail');
const WithdrawalType = () => import('@/views/HomePage/ChildPage/WithdrawalType');
const ActivityRules = () => import('@/components/ActivityRules/ActivityRules');
const GrabRed = () => import('@/views/GrabRed/GrabRed');
const ConfigRed = () => import('@/views/GrabRed/ConfigRed');
const BagDetailed = () => import('@/views/GrabRed/BagDetailed');
const GetDetailed = () => import('@/views/GrabRed/GetDetailed');

// 重写路由push方法,阻止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 重写路由replace方法,阻止重复点击报错
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    // children: [
    //     {
    //       name: "Withdrawal",
    //       path: '/home/withdrawal',
    //       component: Withdrawal
    // }],
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: Withdrawal,
  },
  {
    path: '/moneyDetail',
    name: 'MoneyDetail',
    component: MoneyDetail,
  },
  {
    path: '/withdrawalType',
    name: 'WithdrawalType',
    component: WithdrawalType,
  },
  {
    path: '/activityRules',
    name: 'ActivityRules',
    component: ActivityRules,
  },
  {
    path: '/grabRed',
    name: 'GrabRed',
    component: GrabRed,
  },
  {
    path: '/configRed',
    name: 'ConfigRed',
    component: ConfigRed,
  },
  {
    path: '/bagDetailed',
    name: 'BagDetailed',
    component: BagDetailed,
  },
  {
    path: '/getDetailed',
    name: 'GetDetailed',
    component: GetDetailed,
  },
];

const router = new VueRouter({
  mode: 'hash',
  // mode: "history",
  routes,
});

export default router;
