import Vue from 'vue';
import App from './App.vue';

// import VConsole from "vconsole";
import '@/components/Toast/index.css';
import Toast from '@/components/Toast/index';
import VueClipboard from 'vue-clipboard2';

import router from './router';

Vue.config.productionTip = false;

// 设置根rem以及相关视口配置
import remConfig from './config/remConfig.js';
remConfig();
Vue.prototype.$bus = new Vue();

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.use(Toast);

// if (location.origin.indexOf(".pre") > -1) {
// new VConsole()
// }

import './config/getParams';

new Vue({
  render: (h) => h(App),
  router,
}).$mount('#app');
