/**
 * 信息传输到IOS
 * @param {*} method 方法名
 * @param {*} data 数据
 * @returns
 */
export const postMsgToIos = (method, data = null) => {
  if (!method) return;
  window?.webkit?.messageHandlers[method]?.postMessage(data);
};

export const isIosWebView = (method) => {
  if (method) {
    return window?.webkit?.messageHandlers[method];
  }
  return window?.webkit?.messageHandlers;
};
