<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
      <!-- <home-page></home-page> -->
    </keep-alive>
  </div>
</template>

<script>
// import HomePage from '@/views/HomePage/HomePage'
export default {
  name: 'App',
  data() {
    return {};
  },
  created() {
    console.log('父组件created触发');
  },
  // components: {HomePage},
};
</script>

<style lang="less">
/* @import "~@/assets/font/iconfont.css"; */
/* @import "./assets/css/common.less"; */
@import '~@/assets/fonts/iconfont.css';
@import '~@/assets/css/normalize.less';
body {
  font-size: 16px;
}
</style>
